import logo from "./logo.svg";
import "./App.css";
import Homepage from "./components/Homepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Photos from "./components/Photos";
import Navigation from "./components/Navigation";
import Guest from "./components/Guest";
import Story from "./components/Story";
import Rsvp from "./components/RSVP";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Homepage />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/guest" element={<Guest />} />
        <Route path="/story" element={<Story />} />
        <Route path="/rsvp" element={<Rsvp />} />
      </Route>
    </Routes>
  );
}

export default App;

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const Rsvp = () => {
  const location = {
    lat: 34.06354904174805,
    lng: -118.13316345214844,
  };

  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps/dir/?api=1&$destination=${location.lat},${location.lng}`;
    window.open(url, "_blank");
  };

  const defaultCenter = location;

  return (
    <div className="location-detail flex flex-col items-center">
      <div className="text-2xl pb-4">See the Location Here</div>
      <div className="rounded-2xl w-11/12 justify-center pb-6">
        <LoadScript googleMapsApiKey="AIzaSyAiKz6L79mvYQjVbP_tchixKm3m_UDSNFc">
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={19}
            center={defaultCenter}
          >
            <Marker position={location} title="My location" />
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="">
        <div className="text-2xl pb-4">Address:</div>
        <div className="text-xl pb-2">Capital Seafood Restaurant</div>
        <div className="flex flex-col justify-center items-center pb-4">
          <div className="text-xl">755 W Garvey Ave</div>
          <div className="text-xl">Monterey Park, CA 91754</div>
        </div>
      </div>
      <div className="button pb-6">
        <div
          className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={openGoogleMaps}
        >
          Navigate
        </div>
      </div>
    </div>
  );
};

export default Rsvp;

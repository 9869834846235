import { useEffect, useState } from "react";

const Homepage = () => {
  //Get countdown time
  const targetDate = new Date("December 14, 2024 00:00:00").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    };
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="">
      <div className="flex justify-center">
        <img
          className="max-w-xs"
          src="./png-assets/wedding-logo-600-cropped.png"
          alt="wedding-logo"
        />
      </div>
      <div className="name-text flex flex-col gap-4 pt-6">
        <div className="flex justify-center">
          <div className="text-2xl font-bold font-mono">
            CHINGSAN & KIMHEANG
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-1">
          <div className="text-xl">
            <h3>{timeLeft.days} DAYS TO GO!</h3>
          </div>
          <div className="text-xl">
            <h3>DECEMBER 14, 2024</h3>
          </div>
        </div>
      </div>
      <div className="photo-homepage flex flex-col justify-center items-center pb-6">
        {/* picture-1 */}
        <div className="rounded-lg w-11/12 pt-6 pb-6">
          <img
            src="./png-assets/kodak-gold-200.jpg"
            alt="homepage-pic1"
            className="w-full h-auto rounded-2xl"
          />
        </div>

        {/* picture-2 */}
        <div className="flex flex-row justify-between py-2 w-11/12">
          <div className="text-xl font-medium">OUR STORIES</div>
          <div className="text-md underline decoration-sky-500">
            See more...
          </div>
        </div>
        <div className="rounded-lg w-11/12 pb-6">
          <img
            src="./png-assets/kodak-gold-200-2.jpg"
            alt="homepage-pic1"
            className="w-full h-auto rounded-2xl"
          />
        </div>
        {/* picture-2 */}
        <div className="flex flex-row justify-between py-2 w-11/12">
          <div className="text-xl font-medium">
            <span>YOUR PHOTOS</span>
          </div>
          <div className="text-md underline decoration-sky-500">
            <span>See more...</span>
          </div>
        </div>
        <div className="rounded-lg w-11/12">
          <img
            src="./png-assets/kodak-gold-200-3.jpg"
            alt="homepage-pic1"
            className="w-full h-auto rounded-2xl"
          />
        </div>
      </div>

      <div className="homepage-content"></div>
    </div>
  );
};
export default Homepage;

import { Fragment } from "react";
import { Link, Outlet } from "react-router-dom";

const Navigation = () => {
  return (
    <div className="navbar-top">
      <Fragment>
        <nav className="flex justify-center p-5 font-serif text-lg tracking-widest text-[#6b8e23]">
          <ul className="flex gap-8 list-none p-8 m-0">
            <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 active:border-[#6b8e23] transition duration-300"
                to="/"
              >
                Home
              </Link>
            </li>
            {/* <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/story"
              >
                Our Story
              </Link>
            </li> */}
            <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/photos"
              >
                Photos
              </Link>
            </li>
            <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/rsvp"
              >
                Location
              </Link>
            </li>
            {/* <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/photos"
              >
                Q+A
              </Link>
            </li> */}
            {/* <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/photos"
              >
                Travel
              </Link>
            </li> */}
            {/* <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/photos"
              >
                To-Do List
              </Link>
            </li> */}
            {/* <li>
              <Link
                className="border-b-2 border-transparent hover:border-[#6b8e23] pb-1 transition duration-300"
                to="/guest"
              >
                Registry
              </Link>
            </li> */}
          </ul>
        </nav>
        <Outlet />
      </Fragment>
    </div>
  );
};
export default Navigation;
